import React, {useEffect, useState} from 'react'
import './ToiletSeatImage.css';
import '../commode.css';
import MyDataHelps from '@careevolution/mydatahelps-js';
import {LoadingIndicator} from '@careevolution/mydatahelps-ui';

export default function () {
    const [loading, setLoading] = useState<boolean>(true);
    const [toiletSeatImageId, setToiletSeatImageId] = useState<string>();

    const loadParticipantInfo = (): Promise<void> => {
        return MyDataHelps.getParticipantInfo().then(participantInfo => {
            let customFields = participantInfo.customFields;
            if (customFields.hasOwnProperty('ToiletSeatImageId')) {
                setToiletSeatImageId(customFields['ToiletSeatImageId'])
            }
        });
    };

    useEffect(() => {
        setLoading(true);
        loadParticipantInfo().then(() => {
            setLoading(false);
        });
    }, []);

    return (
        <div className="toilet-seat-image">
            <div className="toilet-seat-image-title">Review Toilet Seat Image</div>
            {loading &&
            <LoadingIndicator/>
            }
            {!loading && toiletSeatImageId &&
            <img src={`https://designer.mydatahelps.org/Authenticated/ReportViewer/ServeReport.ashx?reportId=${toiletSeatImageId}`} alt='toilet seat image'/>
            }
            {!loading && !toiletSeatImageId &&
            <div className='toilet-seat-image-not-found'>No image found.</div>
            }
        </div>
    )
}