let strings: { [key: string]: string } = {
    "participant-identifier-label": "ID del participante:",
    "app-download-title": "Siguiente: Descargue la aplicación",
    "app-download-text": "Descargarse la aplicación MyDataHelps hace que sea aún más fácil participar.",
    "seat-pending-title": "¡Gracias por participar en nuestro estudio!",
    "seat-pending-text-p1": "Un miembro del equipo del estudio lo llamará pronto para programar una hora para instalar el Heart Seat en su hogar. Si tiene alguna pregunta, llámenos al ",
    "seat-pending-text-p2": " o envíenos un correo electrónico a ",
    "seat-pending-text-p3": ".",
    "seat-install-scheduled-title": "¡Gracias por participar en nuestro estudio!",
    "seat-install-scheduled-text-p1": "Un equipo de instalación visitará su hogar ",
    "seat-install-scheduled-text-p2": " a las ",
    "seat-install-scheduled-text-p3": " para instalar el Heart Seat. Si esta hora de cita ya no funciona para usted, llámenos al ",
    "seat-install-scheduled-text-p4": " o envíenos un correo electrónico a ",
    "seat-install-scheduled-text-p5": ".",
    "seat-installed-six-months-title": "¡Gracias por participar en nuestro estudio!",
    "seat-installed-six-months-text-p1": "Han pasado al menos 6 meses desde que instalamos El Heart Seat en su hogar. Si desea desinstalarlo, llámenos al ",
    "seat-installed-six-months-text-p2": " o envíenos un correo electrónico a ",
    "seat-installed-six-months-text-p3": " para programar una hora para desinstalar el asiento. El equipo del estudio se comunicará con usted 12 meses después de la instalación inicial para programar una visita de desinstalación si no tenemos noticias suyas para entonces. ¡Gracias!",
    "seat-deinstall-scheduled-title": "¡Gracias por participar en nuestro estudio!",
    "seat-deinstall-scheduled-text-p1": "Un equipo de desinstalación visitará su hogar ",
    "seat-deinstall-scheduled-text-p2": " a las ",
    "seat-deinstall-scheduled-text-p3": " para desinstalar El Heart Seat. Si esta hora de cita ya no funciona para usted, llámenos al ",
    "seat-deinstall-scheduled-text-p4": " o envíenos un correo electrónico a ",
    "seat-deinstall-scheduled-text-p5": ".",
    "seat-deinstalled-title": "¡Gracias por participar en nuestro estudio!",
    "seat-deinstalled-text-p1": "¡Gracias por participar en el estudio COMMODE-Seat! Póngase en contacto con nosotros al ",
    "seat-deinstalled-text-p2": " o ",
    "seat-deinstalled-text-p3": " si tiene alguna pregunta.",
    "setup-symptom-log-title": "Iniciar el registro de síntomas",
    "setup-symptom-log-subtitle": "Elija los síntomas y tratamientos que desea seguir",
    "incomplete-task-list-title": "Tareas incompletas",
    "connect-fitbit-title": "Fitbit",
    "manage-symptom-log-title": "Administrar registro de síntomas",
    "edit-symptoms-title": "Editar síntomas",
    "active-symptoms-subtitle": "síntomas activos",
    "edit-treatments-title": "Editar tratamientos",
    "active-treatments-subtitle": "tratamientos activos",
    "edit-reminders-title": "Editar recordatorios",
    "reminders-subtitle-enabled": "Activado",
    "reminders-subtitle-disabled": "Desactivado",
    "symptom-setup-title": "¿Qué síntomas desea registrar?",
    "symptom-setup-description": "Puede decidir qué síntomas desea registrar.  También puedes volver y editar esta lista más tarde.",
    "symptom-setup-done": "Siguiente: Tratamientos",
    "symptom-setup-placeholder": "Nombre del síntoma, p. ej., dolor de cabeza",
    "symptom-edit-description": "Seleccione \"Opciones\" para cambiar el color de los síntomas, activar el registro de la gravedad de los síntomas o silenciar un síntoma.",
    "symptom-edit-done": "Guardar los síntomas",
    "treatment-setup-title": "¿Qué tratamientos desea registrar?",
    "treatment-setup-description": 'Los tratamientos pueden ser cualquier cosa que haga para ayudar a reducir sus síntomas. Para obtener los mejores resultados, céntrese en los tratamientos "según sea necesario" y no en los medicamentos diarios o cosas que siempre hace cada día.',
    "treatment-setup-done": "Siguiente: Recordatorios",
    "treatment-setup-placeholder": "Nombre del tratamiento, p. ej., Tylenol",
    "treatment-edit-description": 'Seleccione "Opciones" para cambiar el color del tratamiento o para silenciar un tratamiento.',
    "treatment-edit-done": "Guardar tratamientos",
    "support": "Apoyo"
};

export default strings;