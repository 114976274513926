import React, { useState } from 'react'
import './ProjectSupport.css'
import MyDataHelps, { ProjectInfo } from '@careevolution/mydatahelps-js'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope'
import { faGlobe } from '@fortawesome/free-solid-svg-icons/faGlobe'
import { faPhone } from '@fortawesome/free-solid-svg-icons/faPhone'
import { useInitializeView } from '../helpers/Initialization';
import { Action, LoadingIndicator } from '@careevolution/mydatahelps-ui'
import language from '../helpers/Language';

export interface ProjectSupportProps {
    innerRef?: React.Ref<HTMLDivElement>
}

export default function (props: ProjectSupportProps) {
    const [loading, setLoading] = useState(true);
    const [projectInfo, setProjectInfo] = useState<ProjectInfo | null>(null);

    useInitializeView(() => {
        MyDataHelps.getProjectInfo().then(function (result) {
            setProjectInfo(result);
            setLoading(false);
        });
    });

    if (projectInfo && !projectInfo.supportEmail && !projectInfo.supportPhone && !(projectInfo.learnMoreLink && projectInfo.learnMoreTitle)) {
        return null;
    }

    return (
        <div ref={props.innerRef} className="project-support">
            <div className="title">{language['support']}</div>
            {loading &&
                <LoadingIndicator/>
            }
            {(projectInfo?.learnMoreLink && projectInfo?.learnMoreTitle) &&
                <Action indicatorIcon={faGlobe} subtitle={projectInfo.learnMoreTitle} onClick={() => MyDataHelps.openEmbeddedUrl(projectInfo.learnMoreLink as string)}/>
            }
            {projectInfo?.supportEmail &&
                <Action indicatorIcon={faEnvelope} subtitle={projectInfo.supportEmail} onClick={() => MyDataHelps.openExternalUrl('mailto:' + projectInfo.supportEmail)}/>
            }
            {projectInfo?.supportPhone &&
                <Action indicatorIcon={faPhone} subtitle={projectInfo.supportPhone} onClick={() => MyDataHelps.openExternalUrl('tel:' + projectInfo.supportPhone)}/>
            }
        </div>
    );
}