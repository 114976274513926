import React from 'react';
import ReactDOM from 'react-dom';
import MyDataHelps from '@careevolution/mydatahelps-js';
import '@careevolution/symptom-shark'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './views/Home';
import { CalendarView, DeviceActivityView, getDayKey, ReportsView, SetupStep, SymptomDetailView } from '@careevolution/symptom-shark';
import './commode.css'
import DayView from './views/DayView';
import { SurveyTasksView } from '@careevolution/mydatahelps-ui';
import ToiletSeatImage from './webviewsteps/ToiletSeatImage';
import language from './helpers/Language';

if (window.location.hostname === 'localhost' && !!process.env.REACT_APP_PARTICIPANT_ACCESS_TOKEN) {
    MyDataHelps.setParticipantAccessToken({'access_token': process.env.REACT_APP_PARTICIPANT_ACCESS_TOKEN!, 'expires_in': 21600, 'token_type': 'Bearer'}, 'https://mydatahelps.org/');
}

function onDaySelected(date: Date) {
    MyDataHelps.openApplication(new URL('/day/?date=' + getDayKey(date), document.location as any).href);
}

function onSymptomSelected(s: String, y: Number, m: Number) {
    MyDataHelps.openApplication(new URL('/symptom/?month=' + m + '&year=' + y + '&symptom=' + s, document.location as any).href, {modal: true});
}

let root = document.getElementById('root');
if (root) {
    ReactDOM.render(
        <React.StrictMode>
            <Router>
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/home/" element={<Home/>}/>
                    <Route path="/surveytasks/" element={<SurveyTasksView presentation="Push"/>}/>
                    <Route path="/calendar/" element={<CalendarView
                        onDaySelected={(d) => onDaySelected(d)}
                        onSymptomSelected={(s, y, m) => onSymptomSelected(s, y, m)}/>}/>
                    <Route path="/day/" element={<DayView/>}/>
                    <Route path="/symptom/" element={<SymptomDetailView/>}/>
                    <Route path="/reports/" element={<ReportsView/>}/>
                    <Route path="/deviceactivity/" element={<DeviceActivityView onDaySelected={(d) => onDaySelected(d)}/>}/>
                    <Route path="/toilet-seat-image/" element={<ToiletSeatImage/>}/>
                </Routes>
            </Router>
        </React.StrictMode>,
        root
    );
}

function router(rootClassName: string) {
    switch (rootClassName) {
        case 'symptom-setup':
            return <SetupStep
                title={language['symptom-setup-title']}
                description={language['symptom-setup-description']}
                doneButtonText={language['symptom-setup-done']}
                initialSetup={true}
                requireItems={true}
                inputPlaceholder={language['symptom-setup-placeholder']}
                concept="Symptoms"
                stepIdentifier="Symptoms"/>
        case 'treatment-setup':
            return <SetupStep
                title={language['treatment-setup-title']}
                description={language['treatment-setup-description']}
                doneButtonText={language['treatment-setup-done']}
                initialSetup={true}
                requireItems={false}
                inputPlaceholder={language['treatment-setup-placeholder']}
                stepIdentifier="Treatments"
                concept="Treatments"/>
        case 'symptom-edit':
            return <SetupStep
                title={language['symptom-setup-title']}
                description={language['symptom-edit-description']}
                doneButtonText={language['symptom-edit-done']}
                initialSetup={false}
                requireItems={true}
                inputPlaceholder={language['symptom-setup-placeholder']}
                concept="Symptoms"/>
        case 'treatment-edit':
            return <SetupStep
                title={language['treatment-setup-title']}
                description={language['treatment-edit-description']}
                doneButtonText={language['treatment-edit-done']}
                initialSetup={false}
                requireItems={false}
                inputPlaceholder={language['treatment-setup-placeholder']}
                concept="Treatments"/>
    }
    return <div/>;
}

let remoteRoot = document.getElementById('remote-root');
if (remoteRoot) {
    ReactDOM.render(router(remoteRoot.className), remoteRoot);
}