import React from 'react';
import {Layout} from '@careevolution/mydatahelps-ui';
import {parseISO} from 'date-fns';
import {LogEntryEdit} from '@careevolution/symptom-shark';

export default function () {
    let date = new Date();

    let search = new URLSearchParams(window.location.search);
    if (search.get("date")) {
        date = parseISO(search.get("date")!)
    }

    return (
        <Layout bodyBackgroundColor="#FFFFFF">
            <LogEntryEdit date={date} promptForReviewAfterDays={7}/>
        </Layout>
    );
}