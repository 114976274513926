let strings: { [key: string]: string } = {
    "participant-identifier-label": "Participant ID:",
    "app-download-title": "Next: Download the App",
    "app-download-text": "Downloading the MyDataHelps app makes it even easer to participate.",
    "seat-pending-title": "Thank you for participating in our study!",
    "seat-pending-text-p1": "A member of the study team will call you soon to set up a time to install The Heart Seat in your home. If you have any questions, please call us at ",
    "seat-pending-text-p2": " or email us at ",
    "seat-pending-text-p3": ".",
    "seat-install-scheduled-title": "Thank you for participating in our study!",
    "seat-install-scheduled-text-p1": "An installation team will visit your home on ",
    "seat-install-scheduled-text-p2": " at ",
    "seat-install-scheduled-text-p3": " to install The Heart Seat. If this appointment time no longer works for you, please please call us at ",
    "seat-install-scheduled-text-p4": " or email us at ",
    "seat-install-scheduled-text-p5": ".",
    "seat-installed-six-months-title": "Thank you for participating in our study!",
    "seat-installed-six-months-text-p1": "It has been at least 6 months since you had The Heart Seat installed in your home. If you would like to have it uninstalled, please call us at ",
    "seat-installed-six-months-text-p2": " or email us at ",
    "seat-installed-six-months-text-p3": " to schedule a time to have the seat uninstalled. The study team will reach out to you 12 months after the initial installation to schedule a deinstallation visit if we don't hear from you by then. Thank you!",
    "seat-deinstall-scheduled-title": "Thank you for participating in our study!",
    "seat-deinstall-scheduled-text-p1": "An deinstallation team will visit your home on ",
    "seat-deinstall-scheduled-text-p2": " at ",
    "seat-deinstall-scheduled-text-p3": " to deinstall The Heart Seat. If this appointment time no longer works for you, please please call us at ",
    "seat-deinstall-scheduled-text-p4": " or email us at ",
    "seat-deinstall-scheduled-text-p5": ".",
    "seat-deinstalled-title": "Thank you for participating in our study!",
    "seat-deinstalled-text-p1": "Thank you for participating in the COMMODE-Seat Study! Please reach out to us at ",
    "seat-deinstalled-text-p2": " or ",
    "seat-deinstalled-text-p3": " if you have any questions.",
    "setup-symptom-log-title": "Start your symptom log",
    "setup-symptom-log-subtitle": "Choose the symptoms and treatments you want to track",
    "incomplete-task-list-title": "Incomplete Tasks",
    "connect-fitbit-title": "Fitbit",
    "manage-symptom-log-title": "Manage Symptom Log",
    "edit-symptoms-title": "Edit Symptoms",
    "active-symptoms-subtitle": "Active Symptoms",
    "edit-treatments-title": "Edit Treatments",
    "active-treatments-subtitle": "Active Treatments",
    "edit-reminders-title": "Edit Reminders",
    "reminders-subtitle-enabled": "Enabled",
    "reminders-subtitle-disabled": "Disabled",
    "symptom-setup-title": "What symptoms do you want to log?",
    "symptom-setup-description": "You can choose what symptoms you want to log.  You can also come back and edit this list later.",
    "symptom-setup-done": "Next: Treatments",
    "symptom-setup-placeholder": "Symptom name, e.g. Headache",
    "symptom-edit-description": "Select 'Options' to change symptom color, enable logging symptom severity or to mute a symptom.",
    "symptom-edit-done": "Save Symptoms",
    "treatment-setup-title": "What treatments do you want to log?",
    "treatment-setup-description": 'Treatments can be anything you do to help reduce your symptoms.  For best results, focus on "as needed" treatments and not daily medications or things that you always do each day.',
    "treatment-setup-done": "Next: Reminders",
    "treatment-setup-placeholder": "Treatment name, e.g. Tylenol",
    "treatment-edit-description": 'Select "Options" to change treatment color or to mute a treatment.',
    "treatment-edit-done": "Save Treatments",
    "support": "Support"
};

export default strings;