import React, { useState } from 'react'
import { Action, Card, CardTitle, ConnectFitbit, Layout, LoadingIndicator, PlatformSpecificContent, StatusBarBackground, SurveyTaskList, TextBlock } from '@careevolution/mydatahelps-ui'
import MyDataHelps, { ParticipantInfo, ProjectInfo } from '@careevolution/mydatahelps-js'
import { convertToSymptomSharkParticipantInfo, DailyLogEntry, getDayKey, LogEntry, symptomSharkData, SymptomSharkParticipantInfo } from '@careevolution/symptom-shark';
import { faBell, faCog } from '@fortawesome/free-solid-svg-icons';
import appStoreDownload from '../assets/app-store-download.svg';
import googlePlayDownload from '../assets/google-play-download.svg';
import logo from '../assets/logo.png';
import { useInitializeView } from '../helpers/Initialization';
import { add, compareAsc, format, parseISO } from 'date-fns';
import './Home.css';
import language from '../helpers/Language';
import ProjectSupport from '../components/ProjectSupport';

type SeatStatus = 'pending' | 'install_scheduled' | 'installed' | 'installed_six_months' | 'deinstall_scheduled' | 'deinstalled';

export default function () {
    const [loading, setLoading] = useState<boolean>(true);
    const [projectInfo, setProjectInfo] = useState<ProjectInfo>();
    const [participantInfo, setParticipantInfo] = useState<ParticipantInfo>();
    const [symptomLogEntry, setSymptomLogEntry] = useState<DailyLogEntry>();
    const [hasSetupSymptomLog, setHasSetupSymptomLog] = useState<boolean>();
    const [installDate, setInstallDate] = useState<Date>();
    const [installTime, setInstallTime] = useState<string>();
    const [seatInstalled, setSeatInstalled] = useState<boolean>();
    const [enrolledAtLeastSixMonths, setEnrolledAtLeastSixMonths] = useState<boolean>();
    const [deinstallDate, setDeinstallDate] = useState<Date>();
    const [deinstallTime, setDeinstallTime] = useState<string>();

    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);

    const loadProjectInfo = (): Promise<void> => {
        return MyDataHelps.getProjectInfo().then(function (projectInfo) {
            setProjectInfo(projectInfo);
        });
    };

    const loadParticipantInfo = (): Promise<void> => {
        return MyDataHelps.getParticipantInfo().then(function (participantInfo) {
            setParticipantInfo(participantInfo);

            let customFields = participantInfo.customFields;

            if (customFields.hasOwnProperty('InstallDate') && customFields['InstallDate']) {
                setInstallDate(parseISO(customFields['InstallDate']));
            }

            if (customFields.hasOwnProperty('InstallTime')) {
                setInstallTime(customFields['InstallTime']);
            }

            if (customFields.hasOwnProperty('SeatInstalled')) {
                setSeatInstalled(customFields['SeatInstalled'] === 'True');
            }

            if (customFields.hasOwnProperty('DeinstallDate') && customFields['DeinstallDate']) {
                setDeinstallDate(parseISO(customFields['DeinstallDate']));
            }

            if (customFields.hasOwnProperty('DeinstallTime')) {
                setDeinstallTime(customFields['DeinstallTime']);
            }

            let enrollmentDate = parseISO(participantInfo.enrollmentDate);
            let sixMonthThreshold = add(enrollmentDate, {days: 60});
            setEnrolledAtLeastSixMonths(compareAsc(today, sixMonthThreshold) >= 0);
        });
    };

    const loadHasSetupSymptomLog = (): Promise<boolean> => {
        return MyDataHelps.querySurveyAnswers({surveyName: 'SetupSymptomTracker'}).then(function (response) {
            let hasSetupSymptomLog = response.surveyAnswers.length > 0;
            setHasSetupSymptomLog(hasSetupSymptomLog);
            return hasSetupSymptomLog;
        });
    };

    const loadSymptomLogEntry = (): Promise<void> => {
        return symptomSharkData.getDailyLogEntries(getDayKey(add(today, {days: -1}))).then(function (logEntries) {
            let todayKey = getDayKey(today);
            if (logEntries.hasOwnProperty(todayKey) && logEntries[todayKey]) {
                setSymptomLogEntry(logEntries[todayKey]);
            } else {
                setSymptomLogEntry(undefined);
            }
        });
    };

    useInitializeView(() => {
        loadProjectInfo().then(() => {
            loadParticipantInfo().then(() => {
                loadHasSetupSymptomLog().then((hasSetupSymptomLog: boolean) => {
                    if (hasSetupSymptomLog) {
                        loadSymptomLogEntry().then(() => {
                            setLoading(false);
                        });
                    } else {
                        setLoading(false);
                    }
                });
            });
        });
    });

    if (loading) {
        return <LoadingIndicator/>
    }

    const openApplication = (relativeUrl: string): void => {
        MyDataHelps.openApplication(new URL(relativeUrl, document.location as any).href);
    };

    const onDaySelected = (date: Date): void => {
        openApplication('/day/?date=' + getDayKey(date));
    };

    const onViewAllTasks = (): void => {
        openApplication('/surveytasks/');
    };

    const onSetupSymptomLog = (): void => {
        MyDataHelps.startSurvey('SetupSymptomTracker');
    };

    const onUpdateSymptoms = (): void => {
        MyDataHelps.startSurvey('UpdateSymptoms');
    };

    const onUpdateTreatments = (): void => {
        MyDataHelps.startSurvey('UpdateTreatments');
    };

    const onUpdateReminders = (): void => {
        MyDataHelps.startSurvey('UpdateReminders');
    };

    const onLinkClicked = (url: string): void => {
        MyDataHelps.openExternalUrl(url);
    };

    let seatStatus: SeatStatus;

    if (seatInstalled && deinstallDate && compareAsc(today, deinstallDate) >= 0) {
        seatStatus = 'deinstalled';
    } else if (seatInstalled && deinstallDate) {
        seatStatus = 'deinstall_scheduled';
    } else if (seatInstalled && enrolledAtLeastSixMonths) {
        seatStatus = 'installed_six_months';
    } else if (seatInstalled) {
        seatStatus = 'installed'
    } else if (installDate) {
        seatStatus = 'install_scheduled';
    } else {
        seatStatus = 'pending';
    }

    let symptomSharkParticipantInfo: SymptomSharkParticipantInfo = convertToSymptomSharkParticipantInfo(participantInfo!);

    return (
        <Layout bodyBackgroundColor="#f5f5f5">
            <StatusBarBackground/>
            <div className="home-header">
                <div/>
                <div className="home-header-project-logo">
                    <img src={logo} alt="logo"/>
                </div>
                <div className="home-header-project-info">
                    <div className="home-header-project-name" style={{color: projectInfo!.organization.color}}>{projectInfo!.name}</div>
                    <div className="home-header-organization-name">{projectInfo!.organization.name}</div>
                </div>
                <div/>
            </div>
            <div className="home-participant-identifier">{language['participant-identifier-label']} {participantInfo!.participantIdentifier}</div>
            <PlatformSpecificContent platforms={["Web"]}>
                <Card>
                    <TextBlock>
                        <p><strong>{language['app-download-title']}</strong></p>
                        <div className="card-subtitle" style={{marginBottom: '16px'}}>
                            {language['app-download-text']}
                        </div>
                        <a target="_blank" className="app-download-link" href="https://play.google.com/store/apps/details?id=com.careevolution.mydatahelps&hl=en_US&gl=US">
                            <img style={{height: '44px'}} src={googlePlayDownload} alt="Download on the Google Play Store"/>
                        </a>
                        <a target="_blank" className="app-download-link" href="https://apps.apple.com/us/app/mydatahelps/id1286789190">
                            <img style={{height: '44px'}} src={appStoreDownload} alt="Download on the Apple App Store"/>
                        </a>
                    </TextBlock>
                </Card>
            </PlatformSpecificContent>
            {seatStatus === 'pending' &&
            <Card>
                <CardTitle title={language['seat-pending-title']}/>
                <TextBlock>{language['seat-pending-text-p1']}<a onClick={() => onLinkClicked('tel:5085231795')}>(508)-523-1795</a>{language['seat-pending-text-p2']}<a onClick={() => onLinkClicked('mailto:smartseat@umassmed.edu')}>smartseat@umassmed.edu</a>{language['seat-pending-text-p3']}</TextBlock>
            </Card>
            }
            {seatStatus === 'install_scheduled' &&
            <Card>
                <CardTitle title={language['seat-install-scheduled-title']}/>
                <TextBlock>{language['seat-install-scheduled-text-p1']}{format(installDate!, 'EEEE, MMMM do, yyyy')}{language['seat-install-scheduled-text-p2']}{installTime}{language['seat-install-scheduled-text-p3']}<a onClick={() => onLinkClicked('tel:5085231795')}>(508)-523-1795</a>{language['seat-install-scheduled-text-p4']}<a onClick={() => onLinkClicked('mailto:smartseat@umassmed.edu')}>smartseat@umassmed.edu</a>{language['seat-install-scheduled-text-p5']}</TextBlock>
            </Card>
            }
            {seatStatus === 'installed_six_months' &&
            <Card>
                <CardTitle title={language['seat-installed-six-months-title']}/>
                <TextBlock>{language['seat-installed-six-months-text-p1']}<a onClick={() => onLinkClicked('tel:5085231795')}>(508)-523-1795</a>{language['seat-installed-six-months-text-p2']}<a onClick={() => onLinkClicked('mailto:smartseat@umassmed.edu')}>smartseat@umassmed.edu</a>{language['seat-installed-six-months-text-p3']}</TextBlock>
            </Card>
            }
            {seatStatus === 'deinstall_scheduled' &&
            <Card>
                <CardTitle title={language['seat-deinstall-scheduled-title']}/>
                <TextBlock>{language['seat-deinstall-scheduled-text-p1']}{format(deinstallDate!, 'EEEE, MMMM do, yyyy')}{language['seat-deinstall-scheduled-text-p2']}{deinstallTime}{language['seat-deinstall-scheduled-text-p3']}<a onClick={() => onLinkClicked('tel:5085231795')}>(508)-523-1795</a>{language['seat-deinstall-scheduled-text-p4']}<a onClick={() => onLinkClicked('mailto:smartseat@umassmed.edu')}>smartseat@umassmed.edu</a>{language['seat-deinstall-scheduled-text-p5']}</TextBlock>
            </Card>
            }
            {seatStatus === 'deinstalled' &&
            <Card>
                <CardTitle title={language['seat-deinstalled-title']}/>
                <TextBlock>{language['seat-deinstalled-text-p1']}<a onClick={() => onLinkClicked('tel:5085231795')}>(508)-523-1795</a>{language['seat-deinstalled-text-p2']}<a onClick={() => onLinkClicked('mailto:smartseat@umassmed.edu')}>smartseat@umassmed.edu</a>{language['seat-deinstalled-text-p3']}</TextBlock>
            </Card>
            }
            {!hasSetupSymptomLog &&
            <Card>
                <Action title={language['setup-symptom-log-title']} subtitle={language['setup-symptom-log-subtitle']} onClick={() => onSetupSymptomLog()}/>
            </Card>
            }
            {hasSetupSymptomLog &&
            <LogEntry date={today} participantInfo={symptomSharkParticipantInfo!} onDaySelected={(d) => onDaySelected(d)} logEntry={symptomLogEntry}/>
            }
            <Card>
                <SurveyTaskList status="incomplete" limit={3} title={language['incomplete-task-list-title']} onDetailLinkClick={() => onViewAllTasks()}/>
            </Card>
            <Card>
                <ConnectFitbit title={language['connect-fitbit-title']}/>
            </Card>
            {hasSetupSymptomLog &&
            <Card className="manage-symptom-log">
                <CardTitle title={language['manage-symptom-log-title']}/>
                <Action indicatorIcon={faCog} title={language['edit-symptoms-title']} subtitle={symptomSharkParticipantInfo!.symptoms.length + ' ' + language['active-symptoms-subtitle']} onClick={() => onUpdateSymptoms()}/>
                <Action indicatorIcon={faCog} title={language['edit-treatments-title']} subtitle={symptomSharkParticipantInfo!.treatments.length + ' ' + language['active-treatments-subtitle']} onClick={() => onUpdateTreatments()}/>
                <Action className="action-with-icon" title={language['edit-reminders-title']} onClick={() => onUpdateReminders()} indicatorIcon={faBell} subtitle={symptomSharkParticipantInfo!.reminder1Enabled ? language['reminders-subtitle-enabled'] : language['reminders-subtitle-disabled']}/>
            </Card>
            }
            <Card>
                <ProjectSupport/>
            </Card>
        </Layout>
    )
}